<template>
    <div class="container pt-3">
        <div v-text="demo"></div>
    </div>
</template>

<script>

export default {
  name: 'ArrayDemoPage',
  components: {
  },
  data() {
    return {
        demo: 'test', // 原來的資料是 test
        demoArray: [
        { id: 1, name: "B093415", description: "採購衛星定位儀" },
        { id: 2, name: "B092607", description: "飲水機設備汰換" },
        { id: 3, name: "B092514", description: "大型失事搶救器材運送車乙輛" }
      ],
    };
  },
  mounted() {
    
    this.demoArray.forEach((item, index) => {
        console.log(item, index, this.demo); // 這裡的 this 將指向外部的 this
        if (item.id == 3) {
            // 修改外部 data 資料
            this.demo = item.description
        }
    });
  }
}

</script>
  