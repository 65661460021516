import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import TableDataSelectPage from '../views/TableDataSelectPage.vue'
import VueQuillPage from '../views/VueQuillPage.vue'
import FilePondPage from '../views/FilePondPage.vue'
import DateDemoPage from '../views/DateDemoPage.vue'
import DateDemoPage2 from '../views/DateDemoPage2.vue'
import DateDemoPage3 from '../views/DateDemoPage3.vue'
import MutiSelectPage from '../views/MutiSelectPage.vue'
import ConstDemoPage from '../views/ConstDemoPage.vue'
import ArrayDemoPage from '../views/ArrayDemoPage.vue'
import ListMasterDetailPage from '../views/ListMasterDetailPage.vue'
import ListMasterDetailPage2 from '../views/ListMasterDetailPage2.vue'


const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/table-data-select',
    name: 'TableDataSelectPage',
    component: TableDataSelectPage
  },
  {
    path: '/quill',
    name: 'VueQuillPage',
    component: VueQuillPage
  },
  {
    path: '/file-pond',
    name: 'FilePondPage',
    component: FilePondPage
  },
  {
    path: '/date-demo',
    name: 'DateDemoPage',
    component: DateDemoPage
  },
  {
    path: '/date-demo2',
    name: 'DateDemoPage2',
    component: DateDemoPage2
  },
  {
    path: '/date-demo3',
    name: 'DateDemoPage3',
    component: DateDemoPage3
  },
  {
    path: '/mutiselect',
    name: 'MutiSelectPage',
    component: MutiSelectPage
  },
  {
    path: '/const',
    name: 'ConstDemoPage',
    component: ConstDemoPage
  },
  {
    path: '/array',
    name: 'ArrayDemoPage',
    component: ArrayDemoPage
  },
  {
    path: '/list-m2d',
    name: 'ListMasterDetailPage',
    component: ListMasterDetailPage
  },
  {
    path: '/list-m2d2',
    name: 'ListMasterDetailPage2',
    component: ListMasterDetailPage2
  }

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router