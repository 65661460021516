<template>
  <div class="container pt-3">
    <h1>多檔案上傳 Upload File</h1>
    <p class="py-3">
      <BasicFilePond></BasicFilePond>
    </p>
    <h1>單一檔案上傳 Singal Upload File</h1>
    <p class="py-3">
      <BasicFileUpload></BasicFileUpload>
    </p>
  </div>
</template>

<script>
import BasicFilePond from "../components/FilePond/BasicFilePond.vue";
import BasicFileUpload from "../components/FilePond/BasicFileUpload.vue";

export default {
  name: "FilePondPage",
  components: {
    BasicFilePond,
    BasicFileUpload
  },
  data: function() {
    return {
      
    };
  },
  methods: {
    
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>