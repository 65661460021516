<template>
  <div class="container pt-3">
    <h1>一般簡易日期選擇器</h1>
    <div class="py-3">
      <div v-text="date"></div>
      <VueDatePicker 
        v-model="date"
        locale="zh"
        format="yyyy/MM/dd"
        :enable-time-picker="false"
        text-input
      ></VueDatePicker>
    </div>
  </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: { VueDatePicker },
  data() {
    return {
      date: null,
    };
  },
  methods: {

  },
};
</script>
