<template>
  <div>
    <h2>使用參數設定的範例</h2>
    <div v-text="content"></div>
    <!-- 記得設定 eslint -->
    <QuillEditor v-model:content="content" :options="quillOptions" />
  </div>
</template>

<script>
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { ref, defineComponent } from "vue";
import { QuillEditor } from "@vueup/vue-quill";
// 從外部引入 Quill 的配置選項
import quillOptions from "@/config/quillOptions";

export default defineComponent({
  components: {
    QuillEditor,
  },
  setup() {
    const content = ref(null); // 使用 ref 創建一個響應式參考
    return { content, quillOptions };
  },
});
</script>
