<template>
  <div id="app">
    <VueMultiselect
      v-model="value"
      :options="options"
      :multiple="true"
      track-by="library"
      :custom-label="customLabel"
    >
    </VueMultiselect>
    <h3>選取的變數資料</h3>
    <pre>{{ value }}</pre>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
export default {
  components: { VueMultiselect },
	data() {
    return {
      value: { language: 'JavaScript', library: 'Vue-Multiselect' },
      options: [
        {	language: 'JavaScript', library: 'Vue.js' },
        { language: 'JavaScript', library: 'Vue-Multiselect' },
        { language: 'JavaScript', library: 'Vuelidate' }
      ]
    }
	},
  methods: {
    customLabel(option) {
      return `${option.library} - ${option.language}`
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
