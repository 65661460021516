<template>
    <div class="blog-summary-list pt-3">
      <!-- 使用 v-for 指令來迴圈渲染每篇博客的摘要 -->
      <blog-summary
        v-for="post in posts"
        :key="post.id"
        :title="post.title"
        :authorName="post.author"
        :postDate="post.date"
        :description="post.description"
      />
    </div>
  </template>
  
  <script>
  // 首先，導入 BlogSummary 組件
  import BlogSummary from './BlogSummary.vue';
  
  export default {
    name: 'BlogSummaryList',
    components: {
      BlogSummary
    },
    props: {
      posts: Array // 從外部接收一個包含多篇博客資料的陣列
    }
  }
  </script>
  
  <!-- Scoped CSS -->
  <style scoped>
 
  </style>
  