<template>
  <div>
    <div v-for="record in records" :key="record.RunSno">
      <h3>
        {{ record.RunSno }} - {{ record.RunDate }} - {{ record.RunObserver }}
      </h3>
      <ul>
        <li v-for="detail in record.details" :key="detail.RDetTime">
          Time: {{ detail.RDetTime }}, Way: {{ detail.RDetWay }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListMasterDetailPage",
  components: {},
  data() {
    return {
      mainRecords: [
        {
          RunSno: "2024062401",
          RunDate: "113-06-24",
          RunObserver: "林小明",
        },
        {
          RunSno: "2024062402",
          RunDate: "113-06-25",
          RunObserver: "張小華",
        },
      ],
      details: [
        {
          RunSno: "2024062401",
          RDetTime: "06-24 20:30",
          RDetWay: "05L",
        },
        {
          RunSno: "2024062401",
          RDetTime: "06-24 22:30",
          RDetWay: "05R",
        },
        {
          RunSno: "2024062402",
          RDetTime: "06-25 18:00",
          RDetWay: "06L",
        },
      ],
    };
  },
  computed: {
    // 把兩個 array 關聯起來
    records() {
      return this.mainRecords.map((record) => {
        return {
          ...record,
          details: this.details.filter(
            (detail) => detail.RunSno === record.RunSno
          ),
        };
      });
    },
  },
};
</script>
