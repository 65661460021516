<template>
  <div class="container pt-3">
    <h1>首頁</h1>
    <blog-summary-list :posts="posts" />
  </div>
</template>

<script>
import BlogSummaryList from "../components/BlogSummaryList.vue";

export default {
  name: "HomePage",
  components: {
    BlogSummaryList,
  },
  data() {
    return {
      posts: [
        {
          id: 1,
          title: "TableDataSelect 資料選取元件",
          author: "Justin Liao",
          date: "四月 21 日, 2024",
          description:
            "是一個專門用於操作和管理表格資料選取的 Vue.js 組件。此元件允許用戶從一個資料源選取特定條目，並將它們移動到另一個目標資料源中。這對於需要在不同數據集之間進行動態數據交換和管理的應用場景特別有用。",
        },
        {
          id: 2,
          title: "富文本編輯器 Rich Text Editor",
          author: "Justin Liao",
          date: "四月 21 日, 2024",
          description:
            "<div>請優先考慮 Quill （https://quilljs.com/）</div>" +
            "<div>VueQuill Rich Text Editor for Vue 3 : https://vueup.github.io/vue-quill/</div>" +
            "<div>VueQuill Github : https://github.com/vueup/vue-quill</div>" +
            "",
        },
        {
          id: 3,
          title: "多檔案上傳",
          author: "Justin Liao",
          date: "四月 21 日, 2024",
          description: "請優先考慮FilePond（https://pqina.nl/filepond/）",
        },
      ],
    };
  },
};
</script>

<style></style>
