<!-- 
* ----------------------------------------------
* 資料選取元件 TableDataSelect 20240420
* - description -
* - usage -
* - remark -
* 20240420 init
* ----------------------------------------------
 -->
<template>
  <div class="layout_block2">
    <!-- 左側布局塊 -->
    <div class="layout_block_left2">
      <div class="wrapper page-list overflow-style">
        <TableData
          ref="sourceTable"
          :debugFlag="debugFlag"
          :dataFields="dataFields"
          :dataList="dataSource"
          :cssTable="cssTable"
          :cssTableHeader="cssTableHeader"
          :cssTableRow="cssTableRow"
          @changed="dataSelected('source', $event)"
        >
        </TableData>
      </div>
    </div>

    <!-- 中間的操作按鈕 -->
    <div class="layout_block_arrow">
      <div>
        <button
          @click="moveData('toDest')"
          type="button"
          class="btn-p-w btn-style-auto-s btn-c-gray-black"
        >
          <span>→</span>
        </button>
      </div>
      <br />
      <div>
        <button
          @click="moveData('toSource')"
          type="button"
          class="btn-p-w btn-style-auto-s btn-c-gray-black"
        >
          <span>←</span>
        </button>
      </div>
    </div>

    <!-- 右側布局塊 -->
    <div class="layout_block_right2">
      <div class="wrapper page-list overflow-style">
        <TableData
          ref="destTable"
          :debugFlag="debugFlag"
          :dataFields="dataFields"
          :dataList="dataDest"
          :cssTable="cssTable"
          :cssTableHeader="cssTableHeader"
          :cssTableRow="cssTableRow"
          @changed="dataSelected('dest', $event)"
        >
        </TableData>
      </div>
    </div>
  </div>
</template>

<script>
import TableData from "./TableData.vue";

export default {
  name: "TableDataSelect",
  components: {
    TableData,
  },
  props: {
    dataFields: {
      type: Array,
      required: true,
      default: function() {
        return [];
      }
    },
    dataSource: {
      type: Array,
      required: true,
      default: function() {
        return [];
      }
    },
    dataDest: {
      type: Array,
      required: true,
      default: function() {
        return [];
      }
    },
    cssTable : {
      type:String,
      default : "table-ul-group table-ul-group-inputstyle filter-tablestyle",
    },
    cssTableHeader : {
      type:String,
      default : "filter-tablestyle-tr",
    },
    cssTableRow : {
      type:String,
      default : "tablestyle-tr filter-tablestyle-tr",
    },
    debugFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataSourceSelected: [],
      dataDestSelected: [],
    };
  },
  methods: {
    // 處理子組件發出的選擇變更事件
    dataSelected(type, items) {
      if (type === "source") {
        this.dataSourceSelected = items;
      } else {
        this.dataDestSelected = items;
      }
    },

    // 移動選中的數據項目
    moveData(direction) {
      console.log(
        "moveData",
        direction,
        this.dataSourceSelected,
        this.dataDestSelected
      );

      if (direction === "toDest") {
        this.$emit("move-to-dest", this.dataSourceSelected);
        this.dataSourceSelected = []; // 清空選中的項目
        this.$refs.sourceTable.selectedItems = []; // 直接清空子組件的選中項目
      } else if (direction === "toSource") {
        this.$emit("move-to-source", this.dataDestSelected);
        this.dataDestSelected = []; // 清空選中的項目
        this.$refs.destTable.selectedItems = []; // 直接清空子組件的選中項目
      }
    },
  },
};
</script>

<style scoped>
/* 可在此處添加CSS樣式以改善表格顯示 */
</style>
