<template>
  <div>
    <h2>輸出網頁內容的 Html Quill</h2>
    <div v-text="content"></div>
    <QuillEditor v-model:content="content" content-type="html" theme="snow" />
  </div>
  
</template>

<script>
import { defineComponent, ref } from "vue";
import { QuillEditor } from "@vueup/vue-quill";

export default defineComponent({
  components: {
    QuillEditor,
  },
  setup() {
    const content = ref("<h1>This is header</h1><p>This is paragraph</p>");
    return { content };
  },
});
</script>
