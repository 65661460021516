<template>
  <div>
    <h2>基本範例</h2>

    <div class="container">
      <main class="open_main">
        <div class="another-area page-addbox-flex">
          <div class="table-group nonepadding">
            <h2 class="page-list">
              <div class="page-titlebox">
                <span class="page-title">匯入</span>
              </div>
            </h2>
            <div class="wrapper page-content">
              <div class="group-content">
                <div class="group-content-box">
                  <div class="inputbox-group">
                    <ul>
                      <li>
                        <div class="title-size-1 input-style input-title-gray">
                          格式
                        </div>
                        <a
                          href="../assets/template/sales_template.xlsx"
                          target="_blank"
                          >格式範本</a
                        >
                      </li>
                      <li class="one-field">
                        <div
                          class="title-size-1 input-style input-title-orange"
                        >
                          <span class="font-mark">＊</span>加油商
                        </div>
                        <VueMultiselect
                          v-model="selectedCfact"
                          v-if="allofactCode.length > 0"
                          :options="allofactCode"
                          label="comText"
                          track-by="comVal"
                          :multiple="false"
                          :show-labels="false"
                          :allowEmpty="true"
                          :close-on-select="true"
                          :showNoResults="false"
                          placeholder="請選擇"
                          openDirection="bottom"
                        >
                        </VueMultiselect>
                        <div class="red-font" v-if="Errors.cFact">
                          <span>!</span>{{ Errors.cFact }}
                        </div>
                      </li>
                      <li class="inputbox-textarea">
                        <div
                          class="title-size-1 input-style input-title-orange"
                        >
                          <span class="font-mark">＊</span>匯入檔案
                        </div>
                        <input
                          type="file"
                          class="input-size-1 input-style"
                          ref="fileInput"
                          accept=".xlsx"
                        />
                        <div class="red-font" v-if="Errors.cFile">
                          <span>!</span>{{ Errors.cFile }}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <!--按鈕-->
                  <div class="page-add-btnbox">
                    <div class="line"></div>
                    <div class="group">
                      <span class="button-group">
                        <button
                          type="button"
                          class="btn-style-auto btn-c-blue"
                          @click="ImportDate"
                        >
                          <span>確定匯入</span>
                        </button>
                        <button
                          type="button"
                          id="openmain_close"
                          class="btn-style-auto btn-c-gray-deep"
                          @click="controlShadow('N')"
                        >
                          <span>關閉視窗</span>
                        </button>
                      </span>
                    </div>
                  </div>
                  <!--按鈕-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// VueMultiselect
import VueMultiselect from "vue-multiselect";

export default {
  name: "BasicFilePond",
  components: {
    VueMultiselect,
  },
  data() {
    return {
      rowCount: 0, //實際筆數
      perPage: 10, //一頁的資料數
      currentPage: 1, //目前頁數
      posts: [], //post傳接的MD

      selectItem: [], //挑選綁定的陣列
      selectAll: null, //全選鈕
      sortCol: "OSalesDate", //目前排序欄位
      sortDir: "desc", //目前排序順序

      sortData: ["OSalesDate"], //排序欄位陣列
      alloComcode: [],
      allofactCode: [
        {
          comVal: "01",
          comText: "中油",
        },
        {
          comVal: "02",
          comText: "佳的星",
        },
        {
          comVal: "03",
          comText: "文久",
        },
      ],
      selectedOComcode: null,
      selectedOfactCode: null,
      qoSalesDateS: "",
      qoSalesDateE: "",
      errDate: "",
      showshadow: false,
      selectedCfact: ["加油商 A", "加油商 B"],
      Errors: {
        cFact: "",
        cFile: "",
      },
    };
  },

  methods: {
    handleFilePondInit: function () {
      // FilePond instance methods are available on `this.$refs.pond`

      /* eslint-disable */
      console.log("FilePond has initialized");
    },
    // 記得加上 async
    async ImportDate() {
      var errmsg = "0";
      Object.keys(this.Errors).forEach((item) => {
        this.Errors[item] = "";
      });

      if (!this.selectedCfact) {
        errmsg = "1";
        this.Errors.cFact = "請挑選加油商";
      }

      const uploadfile = this.$refs.fileInput.files[0];
      if (typeof uploadfile == "undefined") {
        errmsg = "1";
        this.Errors.cFile = "請選擇匯入檔案";
      }

      if (errmsg == "1") {
        return;
      }

      //上傳檔案
      const formData = new FormData();
      formData.append("file", uploadfile);
      formData.append("Cfact", this.selectedCfact.comVal);
      const url = "/api/BAS_OilSalesVal";

      // FormData Log 內容的方法
      console.log("FormData entries:");
      formData.forEach((value, key) => {
        console.log(key, value);
      });

      // POST 的寫法
      try {
        const response = await fetch(url, {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const result = await response.json();
          console.log("Success:", result);
        } else {
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  },
};
</script>
