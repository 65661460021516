<template>
    <div class="container pt-3">
        <h1>常數 Const Demo</h1>
        <h2>API</h2>
        <div v-text="USER_ENDPOINT"></div>
        <div v-text="PRODUCT_ENDPOINT"></div>
        <h2>常數</h2>
        <div v-text="data.message"></div>
        <div v-text="data.empployee"></div>
        <div v-text="data.demoData"></div>
        <div v-text="data2"></div>
        <div></div>
    </div>
</template>

<script>
import { USER_ENDPOINT, PRODUCT_ENDPOINT } from '@/constants/apiUrls';
import { data, data2 } from '@/constants/index';

export default {
  name: 'ConstDemoPage',
  components: {
  },
  data() {
    return {
        USER_ENDPOINT: USER_ENDPOINT,
        PRODUCT_ENDPOINT: PRODUCT_ENDPOINT,
        data : data,
        data2 : data2
    };
  },
}

</script>
  