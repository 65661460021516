<template>
  <div>
    <!-- 使用 v-for 迴圈遍歷每筆主檔資料 -->
    <div v-for="record in mainRecords" :key="record.RunSno">
      <h3>
        {{ record.RunSno }} - {{ record.RunDate }} - {{ record.RunObserver }}
      </h3>
      <!-- 使用 details 方法來獲取對應的明細資料 -->
      <ul>
        <li v-for="detail in getDetails(record.RunSno)" :key="detail.RDetTime">
          Time: {{ detail.RDetTime }}, Way: {{ detail.RDetWay }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListMasterDetailPage2",
  components: {},
  data() {
    return {
      // 主檔資料
      mainRecords: [
        {
          RunSno: "2024062401",
          RunDate: "113-06-24",
          RunObserver: "林小明",
        },
        {
          RunSno: "2024062402",
          RunDate: "113-06-25",
          RunObserver: "張大華",
        },
      ],
      // 明細資料
      details: [
        {
          RunSno: "2024062401",
          RDetTime: "06-24 20:30",
          RDetWay: "05L",
        },
        {
          RunSno: "2024062401",
          RDetTime: "06-24 22:30",
          RDetWay: "05R",
        },
        {
          RunSno: "2024062402",
          RDetTime: "06-25 18:00",
          RDetWay: "06L",
        },
      ],
    };
  },
  methods: {
    // 獲取對應的明細資料
    getDetails(runSno) {
      return this.details.filter(detail => detail.RunSno === runSno);
    }
  }
};
</script>
