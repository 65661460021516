<!-- 
* ----------------------------------------------
* 資料選取元件範例程式 TableDataSelectDemo 20240420
* - description -
* - usage -
* - remark -
* 20240420 init
* ----------------------------------------------
 -->
<template>
    <div class="container pt-3">
    <h1>資料選取元件 Table Data Select</h1>
    <h2>簡單寫法</h2>
    <TableDataSelectDemo></TableDataSelectDemo>
    <h2>完整寫法</h2>
    <TableDataSelectDemo2></TableDataSelectDemo2>
    <h2>表單連動</h2>
    <TableDataSelectDemo3></TableDataSelectDemo3>
  </div>
</template>

<script>
import TableDataSelectDemo from '../components/TableDataSelectDemo.vue'
import TableDataSelectDemo2 from '../components/TableDataSelectDemo2.vue'
import TableDataSelectDemo3 from '../components/TableDataSelectDemo3.vue'

export default {
  name: 'TableDataSelectPage',
  components: {
    TableDataSelectDemo,
    TableDataSelectDemo2,
    TableDataSelectDemo3
  }
}
</script>

<style scoped>
/* 可在此處添加CSS樣式以改善表格顯示 */
</style>




