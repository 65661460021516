<template>
  <div class="container pt-3">
    <h1>民國簡易日期選擇器</h1>
    <div class="py-3">
      <div v-text="date"></div>
      <VueDatePicker 
        v-model="date"
        locale="zh"
        month-name-format="short"
        :format="formatDate"
        :enable-time-picker="false"
        text-input
      ></VueDatePicker>
    </div>
  </div>
</template>
<script>
import { format } from 'date-fns';
import { zhTW } from 'date-fns/locale';
import VueDatePicker from '@vuepic/vue-datepicker';
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: { VueDatePicker },
  data() {
    return {
      date: new Date(),
    };
  },
  methods: {
    // 将公元年转换为民国年
    ROC2Utc(adYear) {
      return adYear + 1911;
    },
    Utc2ROC(adYear) {
      return adYear - 1911;
    },
    // 檢查並解析民國日期格式，轉換為西元日期
    parseROCDate(rocDateString) {
      // 使用正則表達式檢查並解析日期
      const match = rocDateString.match(/^民國(\d+)年(\d+)月(\d+)日$/);
      if (match) {
        const rocYear = match[1]; // 民國年份
        const month = match[2]; // 月份
        const day = match[3]; // 日期
        const adYear = this.ROC2Utc(rocYear); // 將民國年份轉換為西元年份
        // 建立新的Date對象
        return new Date(adYear, month - 1, day); // 月份從0開始，因此減1
      } else {
        return null; // 如果格式不匹配，返回null
      }
    },

    parseDate(dateString) {
        // 確保 dateString 是一個字符串
  if (typeof dateString !== 'string') { 
    console.error('parseDate 函式預期的參數應為字符串類型' ,dateString, typeof dataString);
    return null;
  }

  // 正則表達式檢查是否是公元年格式
  const adRegex = /^(\d{4})\/(\d{1,2})\/(\d{1,2})$/;
  const adMatch = dateString.match(adRegex);

  if (adMatch) {
    const year = parseInt(adMatch[1], 10);
    const month = parseInt(adMatch[2], 10);
    const day = parseInt(adMatch[3], 10);

    return new Date(year, month - 1, day);
  }

  // 正則表達式檢查是否是民國年格式
  const rocRegex = /^(\d+)\/(\d{1,2})\/(\d{1,2})$/;
  const rocMatch = dateString.match(rocRegex);

  if (rocMatch) {
    const rocYear = parseInt(rocMatch[1], 10);
    const month = parseInt(rocMatch[2], 10);
    const day = parseInt(rocMatch[3], 10);
    const adYear = rocYear + 1911;

    return new Date(adYear, month - 1, day);
  }

  // 如果兩種格式都不符合，返回null
  return null;
},
    // 格式化日期為民國日期
    formatDate(date) {

      const formattedDate = format(date, 'yyyy年MM月dd日 EEEE', { locale: zhTW });
console.log(formattedDate);
return formattedDate

    }
  },
};
</script>
