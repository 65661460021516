<template>
  <div class="container pt-3">
    <h1>中文日期選擇器</h1>
    <p class="py-3">
      <!-- 组件标签名称需要与 script 中注册的名称相符 -->
      <datepicker
        v-model="rocDate"
        :format="formatDate"
        :action-row="{ showNow: true }"
        :enable-time-picker="false"
        position="left"
        placeholder="請輸入日期"
        now-button-label="今天"
        cancel-text="關閉"
        select-text="選擇"
        text-input
      >
        <template
          #month-year="{
            month,
            year,
            months,
            years,
            updateMonthYear,
            handleMonthYearChange,
          }"
        >
          <div class="custom-month-year-component">
            <select
              class="select-input"
              :value="year"
              @change="updateYear($event, updateMonthYear, month)"
            >
              <option v-for="y in years" :key="y.value" :value="y.value">
                {{ convertToROCYear(y.value) }}
              </option>
            </select>

            <select
              class="select-input"
              :value="month"
              @change="updateMonth($event, updateMonthYear, year)"
            >
              <option v-for="m in months" :key="m.value" :value="m.value">
                {{ m.text }}
              </option>
            </select>
          </div>
          <div class="icons">
            <span class="custom-icon" @click="handleMonthYearChange(false)">
            </span>
            <span class="custom-icon" @click="handleMonthYearChange(true)">
            </span>
          </div>
        </template>
      </datepicker>
    </p>
  </div>
</template>
<script>
import { format } from "date-fns";
import { zhTW } from "date-fns/locale"; // 导入繁体中文的 locale
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      rocDate: null, // new Date(),  目前日期
      chineseMonths: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ], // 中文月份
    };
  },
  methods: {
    // 定義更新月份和年份的函數
    updateMonth(event, updateMonthYear, year) {
      const month = +event.target.value;
      updateMonthYear(month, year);
    },

    updateYear(event, updateMonthYear, month) {
      const year = +event.target.value;
      updateMonthYear(month, year);
    },

    // 将公元年转换为民国年
    convertToROCYear(adYear) {
      return adYear - 1911;
    },
    // 格式化日期為民國日期
    formatDate(date) {
      // 檢查 date 是否是一個 Date 對象，如果不是，可能是民國年格式的字符串
      if (!(date instanceof Date)) {
        console.error("Invalid date provided:", date);
        if (date) {
          // 假設 date 是民國年格式 "yyy/MM/dd"，將其轉換為公元年
          const parts = date.split("/");
          if (parts.length === 3) {
            const year = parseInt(parts[0], 10) + 1911; // 民國年轉公元年
            const month = parseInt(parts[1], 10) - 1; // 月份從 0 開始
            const day = parseInt(parts[2], 10);
            date = new Date(year, month, day);
          } else {
            return date; // 如果不符合預期的格式，直接返回原字符串
          }
        } else {
          return null;
        }
      }

      // 檢測轉換後的日期是否有效
      if (isNaN(date.getTime())) {
        console.error("無效的日期:", date);
        return null;
      }

      console.log(date);

      // 計算民國年份
      const rocYear = this.convertToROCYear(date.getFullYear());
      // 使用 date-fns 庫進行日期格式化，將公元年轉換為民國年
      const formattedDate = format(date, "yyyy/MM/dd", { locale: zhTW });
      // 替換公元年為民國年
      return formattedDate.replace(/^\d{4}/, rocYear.toString());
    },

    // 过滤并修改日历中的特定周显示，例如只显示含有15号的周
    calendarFn(weeks) {
      return weeks
        .filter((week) => week.days.some((day) => day.date.getDate() === 15))
        .map((week) => ({
          ...week,
          days: week.days.map((day) => {
            day.classData = { "custom-class": true }; // 为该周的每一天添加自定义样式
            return day;
          }),
        }));
    },
  },
};
</script>
