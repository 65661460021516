<template>
  <div class="container">
    <div class="row align-items-left">
      <!-- Post Title -->
      <div class="row pt-1">
        <!-- Profile Image and Name -->
        <div class="col-auto pe-1">
          <div
            class="author-icon rounded-circle bg-dark text-white text-center align-items-center"
          >
            <i class="fas fa-user"></i>
          </div>
          
        </div>
        <div class="col p-0 author-name" v-text="authorName">
          
        </div>
      </div>
      <div class="row  pt-1">
        <div class="col">
          <h2 v-text="title"></h2>
        </div>
      </div>
    </div>
    <div class="row my-0">
      <!-- Post Description -->
      <div class="col">
        <div class="text-muted" v-html="description"></div>
      </div>
    </div>
    <div class="row my-0">
      <div class="col">
        <span class="text-muted" style="font-size: 0.8rem" v-text="postDate"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogSummary",
  props: {
    title: String,
    authorName: String,
    postDate: String,
    description: String,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<!-- Scoped CSS for styling specific to this component -->
<style scoped>

</style>
