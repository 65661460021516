<!-- 
* ----------------------------------------------
* 資料選取元件範例程式 TableDataSelectDemo 20240420
* - description -
* - usage -
* - remark -
* 20240420 init
* ----------------------------------------------
 -->
<template>

  <div v-if="true" v-text="categorySelected"></div>
  <select class="form-select" aria-label="Default select example"
    v-model="categorySelected"
  >
    <option value="">選擇分類</option>
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
  </select>

  <TableDataSelect
    :debugFlag="false"
    :dataFields="dataFields"
    :dataSource="dataSource"
    :dataDest="dataDest"
    :cssTable="cssTable"
    :cssTableHeader="cssTableHeader"
    :cssTableRow="cssTableRow"
    @move-to-dest="moveToDest"
    @move-to-source="moveToSource"
  >
  </TableDataSelect>
</template>

<script>
import TableDataSelect from './TableDataSelect.vue'

export default {
  name: 'App',
  components: {
    TableDataSelect
  },
  data() {
    return {

      // select
      categorySelected : "",

      /*
      * table data select
      */

      // api 打回來的資料
      dataApi: [
        { id: 1, name: "B093415", category : "1" , description: "採購衛星定位儀" },
        { id: 2, name: "B092607", category : "1" , description: "飲水機設備汰換" },
        { id: 3, name: "B092514", category : "2" , description: "大型失事搶救器材運送車乙輛" }
      ],

      dataFields: [
        { name:"name", caption: "編號", width: "150px", class:"css_d"},
        { name:"category", caption: "分類", width: "50px", class:"css_d"},
        { name:"description", caption: "名稱", width: "550px", class:"css_e" }
      ],
      dataDest: [],
      cssTable : "table-ul-group table-ul-group-inputstyle filter-tablestyle a",
      cssTableHeader : "filter-tablestyle-tr b",
      cssTableRow : "tablestyle-tr filter-tablestyle-tr c",
    };
  },
  computed: {
    dataSource() {
      if (this.categorySelected === "") {
        return this.dataApi;  // 如果沒有選擇任何分類，則顯示所有資料
      } else {
        return this.dataApi.filter(item => item.category === this.categorySelected);
        // 篩選出符合選擇的分類的資料
      }
    }
  },
  methods: {
   
    // 處理從子組件發來的移動到目的地的請求
    moveToDest(selectedItems) {
      this.moveItems(selectedItems, 'toDest');
    },

    // 處理從子組件發來的移動回來源的請求
    moveToSource(selectedItems) {
      this.moveItems(selectedItems, 'toSource');
    },

    /**
     * 移動選中的項目到指定目的地
     * @param {Array} selectedItems - 被選中的項目列表
     * @param {String} direction - 移動方向，'toDest' 表示從來源到目的地，'toSource' 表示從目的地回到來源
     */
     moveItems(selectedItems, direction) {
      if (direction === 'toDest') {
        this.dataDest = this.dataDest.concat(selectedItems); // 將選中項目加入到目的地
        this.dataSource = this.dataSource.filter(item => !selectedItems.includes(item)); // 從來源中移除選中項目
      } else if (direction === 'toSource') {
        this.dataSource = this.dataSource.concat(selectedItems); // 將選中項目加入到來源
        this.dataDest = this.dataDest.filter(item => !selectedItems.includes(item)); // 從目的地移除選中項目
      }
    },

  }
}
</script>

<style scoped>
/* 可在此處添加CSS樣式以改善表格顯示 */
</style>




