<template>
    <div>
      <h2>基本範例</h2>
      <file-pond
        name="test"
        ref="pond"
        allow-multiple="true"
        v-bind:server="myServer"
        v-bind:files="myFiles"
        v-on:init="handleFilePondInit"
      />
    </div>
  </template>
  
  <script>
    // Import Vue FilePond
    import vueFilePond from "vue-filepond";
    // Import FilePond styles
    import "filepond/dist/filepond.min.css";
    import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
    import FilePondPluginImagePreview from "filepond-plugin-image-preview";
    import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
    import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

    // Create component
    const FilePond = vueFilePond(
        FilePondPluginFileValidateType,
        FilePondPluginImageExifOrientation,
        FilePondPluginImagePreview
    );

    export default {
        name: "BasicFilePond",
        components: {
            FilePond,
        },
        data: function() {
            return {
                // fake server to simulate loading a 'local' server file and processing a file
                myServer: {
                    process: (fieldName, file, metadata, load) => {
                        
                        console.log("processing file",{
                            fieldName: fieldName,
                            file: file,
                            metadata: metadata
                        } );
                        
                        // simulates uploading a file
                        setTimeout(() => {
                            load(Date.now());
                        }, 1500);
                    },
                    load: (source, load) => {
                        
                        console.log("loading file", source, load);

                        // simulates loading a file from the server
                        fetch(source)
                            .then((res) => res.blob())
                            .then(load);
                    },
                },
                myFiles: [
                    {
                        source: "photo.jpg",
                        options: {
                            type: "local",
                        },
                    },
                ],
            };
        },
        methods: {
            handleFilePondInit: function() {
            // FilePond instance methods are available on `this.$refs.pond`

            /* eslint-disable */
            console.log("FilePond has initialized");
            },
        },
    };
  </script>
  