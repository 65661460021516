<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container">
      <router-link class="navbar-brand" to="/">BLOCKCODE</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              攻略
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link class="dropdown-item" to="/table-data-select"
                  >資料選取元件 Table Data Select</router-link
                >
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <router-link class="dropdown-item" to="/quill"
                  >富文本編輯器 Rich Text Editor</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/file-pond"
                  >多檔案上傳 Upload File</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/date-demo"
                  >中文日期選擇器 ROC Date Picker </router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/mutiselect"
                  >多選元件 Muti Select</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/const"
                  >常數 Const</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/list-m2d"
                  >父子列表 - 陣列關聯</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/list-m2d2"
                  >父子列表 - 即時查詢</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/">關於</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!--     
  <div class="container">
    <div class="row">
      <div class="col">
    
  </div>
  </div>
  </div> -->
</template>

<script>
export default {
  name: "NavBar",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
