// data.js
export const data = {
  message: "Hello from external file!",
  message2: "Hello again from the same file!",
  empployee: {
    name: "Justin",
    job: "PG",
  },
  demoData: [
    { id: 1, name: "B093415", category: "1", description: "採購衛星定位儀" },
    { id: 2, name: "B092607", category: "1", description: "飲水機設備汰換" },
    {
      id: 3,
      name: "B092514",
      category: "2",
      description: "大型失事搶救器材運送車乙輛",
    },
  ],
  sysType: [
    { id: "", name: "請選擇" },
    { id: "02", name: "工作日誌" },
    { id: "06", name: "品質查核督導稽核作業管理" },
    { id: "07", name: "安衛承攬管理作業" },
    { id: "08", name: "航務其它作業" },
    { id: "09", name: "航燃油使用紀錄維護" },
    { id: "10", name: "設施費用紀錄維護" },
    { id: "11", name: "航空費用紀錄維護" },
    { id: "14", name: "空側消防作業" },
  ],
};

export const data2 = {
  message: "Another Hello from another external file!",
};
