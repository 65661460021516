<!-- 
* ----------------------------------------------
* MutiSelect Demo Page 20240507
* - description -
* - usage -
* - remark -
* 20240507 init
* ----------------------------------------------
 -->
 <template>
    <div class="container pt-3">
    <h1>多選元件 MutiSelect</h1>
    <h2>demo1 一般用法</h2>
    <MutiSelect></MutiSelect>
    <h2>demo2 客製化標籤</h2>
    <MutiSelect2></MutiSelect2>
    <!-- demo3 簡化預設值 -->
    <MutiSelect3></MutiSelect3>
  </div>
</template>

<script>
import MutiSelect from '../components/MutiSelect.vue'
import MutiSelect2 from '../components/MutiSelect2.vue'
import MutiSelect3 from '../components/MutiSelect3.vue'

export default {
  name: 'MutiSelectPage',
  components: {
    MutiSelect,
    MutiSelect2,
    MutiSelect3
  }
}
</script>

<style scoped>
/* 可在此處添加CSS樣式以改善表格顯示 */
</style>




