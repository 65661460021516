<!-- 
* ----------------------------------------------
* 資料表元件 TableData 20240420
* - description -
* 顯示表格資料
* - usage -
* <TableData />
* - remark -
* 20240420 init
* ----------------------------------------------
 -->
 <template>
  <div :class="cssTable">
    <div v-text="selectedItems" v-if="debugFlag"></div>
    <!-- 標題層 -->
    <ul :class="cssTableHeader">
      <li style="width:55px">
        <input
          type="checkbox"
          @change="toggleSelectAll"
          :checked="isAllSelected"
        />
      </li>
      <li v-for="field in dataFields" 
        :key="field.id" 
        :style="{ width: field.width }" v-text="field.caption">
      </li>
    </ul>
    <!-- 表格層 -->
    <ul
      v-for="item in dataList"
      :key="item.id"
      :class="cssTableRow"
      @click="toggleSelection(item)"
    >
      <li style="width:55px">
        <input type="checkbox" :value="item" v-model="selectedItems" />
      </li>
      <!-- <li style="width:150px">{{ item.name }}</li>
      <li style="width:480px">{{ item.description }}</li> -->
      <li v-for="field in dataFields" :key="field.name" 
        :style="{ width: field.width }" 
        :class="field.class"
        v-text="item[field.name]"
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TableData',
  props: {
    dataFields: {
      type: Array,
      required: true,
      default: function() {
        return [];
      }
    },
    dataList: {
      type: Array,
      required: true,
      default: function() {
        return [];
      }
    },
    cssTable : {
      type:String,
      default : "table-ul-group table-ul-group-inputstyle filter-tablestyle",
    },
    cssTableHeader : {
      type:String,
      default : "filter-tablestyle-tr",
    },
    cssTableRow : {
      type:String,
      default : "tablestyle-tr filter-tablestyle-tr",
    },
    debugFlag : { 
      type: Boolean, 
      default: false
    },
  },
  data() {
    return {

      /***********************************/
      /* 常數
       ***********************************/

      /***********************************/
      /* 基本
       ***********************************/

      /***********************************/
      /* 介面資料
       ***********************************/

      selectedItems: [], // 存儲選中項目的物件列表
    };
  },
  computed: {
    // 計算是否所有項目都被選中
    isAllSelected() {
      return this.dataList.length && this.selectedItems.length === this.dataList.length;
    },
  },
  watch: {
    // 監聽 selectedItems 的變化並觸發一個事件向父組件傳遞當前的選中項目
    selectedItems: {
      handler(newVal) {
        this.$emit('changed', newVal);
      },
      deep: true // 深度監聽對象內部變化
    }
  },
  methods: {

    /***********************************/
    /* 共用 function
    /***********************************/


    /***********************************/
    /* 介面互動區                        
    /***********************************/

    // 切換全選與取消全選
    toggleSelectAll(event) {
      this.selectedItems = event.target.checked ? [...this.dataList] : [];
    },

    // 切換單行選取或取消選取
    toggleSelection(item) {
      const index = this.selectedItems.indexOf(item);
      if (index > -1) {
        this.selectedItems.splice(index, 1); // 若已選中，則移除
      } else {
        this.selectedItems.push(item); // 若未選中，則添加
      }
    },

    /***********************************/
    /* API AREA                        
    /***********************************/

  }
}
</script>

<style scoped>
/* 可在此處添加CSS樣式以改善表格顯示 */
.tablestyle-tr:hover {
  cursor: pointer; /* 將鼠標樣式改為指針，表示可點擊 */
}
</style>




