<template>
  <div>
    <h2>基本範例</h2>
    <div v-text="content"></div>
    <!-- 記得設定 eslint -->
    <QuillEditor v-model:content="content" />
  </div>
</template>

<script>
import { ref, defineComponent } from "vue";
import { QuillEditor } from "@vueup/vue-quill";

export default defineComponent({
  components: {
    QuillEditor
  },
  setup() {
    const content = ref(null); // 使用 ref 創建一個響應式參考

    return { content };
  }
});
</script>
