import { createApp } from 'vue'

/* Basic */
import App from './App.vue'
import router from './router'

/* Boostrap */
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

/* ROCDatePicker */
// import ROCDatePicker from 'vue3-roc-date-picker';
// import 'vue3-roc-date-picker/dist/style.css';


createApp(App)
.use(router)
.mount('#app')

// 引入自定義 CSS (確保在 Bootstrap 之後引入)
import './assets/css-bc/main-style.css';

/* Custom css */
import './assets/css/index.css'; 
import './assets/css/globals.css'; 
