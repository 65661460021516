<template>
  <div>
    <h2>支援多媒體檔案排版的 Quill Blot Formatter</h2>
    <div v-text="content"></div>
    <QuillEditor v-model:content="content" :modules="modules" toolbar="full" />
  </div>
</template>
  
<script>
  import { ref, defineComponent } from 'vue'
  import { QuillEditor, Delta } from '@vueup/vue-quill'
  import BlotFormatter from 'quill-blot-formatter'
  
  export default defineComponent({
    components: {
      QuillEditor,
    },
    setup: () => {
      const modules = {
        name: 'blotFormatter',
        module: BlotFormatter,
        options: {
          /* options */
        },
      }
      const content = ref(
        new Delta([
          { insert: 'Gandalf', attributes: { bold: true } },
          { insert: ' the ' },
          { insert: 'Grey', attributes: { color: '#ccc' } },
        ])
      );
  
      return { content, modules }
    },
  })
</script>