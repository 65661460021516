<template>
  <div id="app">
    <h2>demo3 簡化預設值</h2>
    <h3>選取的變數資料</h3>
    <pre v-text="value"></pre>
    <pre v-text="selected"></pre>
    <!-- <h3>Sys Type</h3>
    <pre v-text="sysType"></pre> -->
    <VueMultiselect
      v-model="selected"
      :options="options"
      label="name"
      track-by="id"
      :multiple="true"
      :close-on-select="false"
      placeholder="搜尋標籤"
    >
    </VueMultiselect>
    <h3>清單資料</h3>
    <a href="/MutiSelect3.md">說明</a>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import { data } from "@/constants/index";

export default {
  components: { VueMultiselect },
  data() {
    return {
      selected: null,
      options: data.sysType,
    };
  },
  computed: {
    value: {
      get() {
        if (this.selected) {
          return this.selected.map((item) => item.id);
        } else {
          return null;
        }
      },
      set(newVal) {
        console.debug("觸發 setter!");
        // 檢查 newVal 是否是陣列
        if (Array.isArray(newVal)) {
          // 從 sysType 中查找所有匹配的 id，並收集對應的項目
          this.selected = this.options.filter((item) =>
            newVal.includes(item.id)
          );
          // 如果沒有找到任何項目，你可以選擇設置為空陣列或處理其他邏輯
          if (this.selected.length === 0) {
            console.debug("未找到對應的 IDs:", newVal);
          }
        } else {
          // 如果 newVal 不是陣列，可以選擇重設 selected 或其他錯誤處理
          console.debug("非預期的資料:", newVal);
          this.selected = [];
        }
      },
    },
  },
  methods: {},
  mounted() {
    // 設定方式參考
    this.value = ["02", "06"];
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
