<!-- 
* ----------------------------------------------
* 資料選取元件範例程式 TableDataSelectDemo 20240420
* - description -
* - usage -
* - remark -
* 20240420 init
* ----------------------------------------------
 -->
<template>
  <TableDataSelect
    :debugFlag="false"
    :dataFields="dataFields"
    :dataSource="dataSource"
    :dataDest="dataDest"
    :cssTable="cssTable"
    :cssTableHeader="cssTableHeader"
    :cssTableRow="cssTableRow"
    @move-to-dest="moveToDest"
    @move-to-source="moveToSource"
  >
  </TableDataSelect>
</template>

<script>
import TableDataSelect from './TableDataSelect.vue'

export default {
  name: 'App',
  components: {
    TableDataSelect
  },
  data() {
    return {
      dataFields: [
        { name:"name", caption: "編號", width: "150px", class:"css_d"},
        { name:"description", caption: "名稱", width: "550px", class:"css_e" }
      ],
      dataSource: [
        { id: 1, name: "B093415", description: "採購衛星定位儀" },
        { id: 2, name: "B092607", description: "飲水機設備汰換" },
        { id: 3, name: "B092514", description: "大型失事搶救器材運送車乙輛" }
      ],
      dataDest: [],
      cssTable : "table-ul-group table-ul-group-inputstyle filter-tablestyle a",
      cssTableHeader : "filter-tablestyle-tr b",
      cssTableRow : "tablestyle-tr filter-tablestyle-tr c",
    };
  },
  methods: {
   
    // 處理從子組件發來的移動到目的地的請求
    moveToDest(selectedItems) {
      this.moveItems(selectedItems, 'toDest');
    },

    // 處理從子組件發來的移動回來源的請求
    moveToSource(selectedItems) {
      this.moveItems(selectedItems, 'toSource');
    },

    /**
     * 移動選中的項目到指定目的地
     * @param {Array} selectedItems - 被選中的項目列表
     * @param {String} direction - 移動方向，'toDest' 表示從來源到目的地，'toSource' 表示從目的地回到來源
     */
     moveItems(selectedItems, direction) {
      if (direction === 'toDest') {
        this.dataDest = this.dataDest.concat(selectedItems); // 將選中項目加入到目的地
        this.dataSource = this.dataSource.filter(item => !selectedItems.includes(item)); // 從來源中移除選中項目
      } else if (direction === 'toSource') {
        this.dataSource = this.dataSource.concat(selectedItems); // 將選中項目加入到來源
        this.dataDest = this.dataDest.filter(item => !selectedItems.includes(item)); // 從目的地移除選中項目
      }
    },

  }
}
</script>

<style scoped>
/* 可在此處添加CSS樣式以改善表格顯示 */
</style>




