<template>
  <div class="container pt-3">
    <h1>富文本編輯器 Rich Text Editor</h1>
    <p class="py-3">
      <BasicQuill></BasicQuill>
    </p>
    <p class="py-3">
      <ConfigQuill></ConfigQuill>
    </p>
    <p class="py-3">
      <BlotQuill></BlotQuill>
    </p>
    <p class="py-3">
      <HtmlQuill></HtmlQuill>
    </p>
  </div>
</template>

<script>
/* QuillEditor */
import BasicQuill from "../components/Quill/BasicQuill.vue";
import ConfigQuill from "../components/Quill/ConfigQuill.vue";
import BlotQuill from "../components/Quill/BlotQuill.vue";
import HtmlQuill from "../components/Quill/HtmlQuill.vue";

export default {
  name: "VueQuillPage",
  components: {
    BasicQuill,
    ConfigQuill,
    BlotQuill,
    HtmlQuill,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
